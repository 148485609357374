import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby-link';
import { useLocation } from '@reach/router';
import { Row, Column, Container } from '../Grid';
import Image from '../Image';
import engineeringTypes from './engineering-types.json';
import './styles.scss';

const Subfooter = () => {
  const [randIndex, setRandIndex] = useState(null);
  const { pathname: currentPath } = useLocation();
  const isHome = currentPath === '/';

  useEffect(() => {
    setRandIndex(Math.floor(Math.random() * engineeringTypes.length));
  }, []);

  const mechanicalEngineeringIndex = 0;
  const index = isHome ? mechanicalEngineeringIndex : randIndex;
  const engineeringType =
    typeof randIndex === 'number' ? engineeringTypes[index] : null;

  return (
    engineeringType && (
      <Row className="subfooter__bg-img">
        <Container fullWidth className="subfooter__content-wrapper">
          <Column size={6} offset={1} className="subfooter__content">
            <p className="subfooter__content-title h2 color-yellow">
              {engineeringType.title}
            </p>
            <p className="subfooter__content-body h2 color-white">
              {engineeringType.body}
            </p>
          </Column>
          <Column size={5}>
            <Image
              filename={engineeringType.img}
              className="subfooter__img"
              onClick={() => navigate('/students')}
            />
          </Column>
        </Container>
      </Row>
    )
  );
};

export default Subfooter;
