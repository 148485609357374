import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Hero from '../../components/Hero';
import Resource from '../../components/Resource';
import Subfooter from '../../components/Subfooter/index';
import '../../styles/pages/_educators.scss';

const DigitalLessonBundlesPage = () => {
  return (
    <Layout title="Digital Lesson Bundles">
      <Hero
        mediaSource="top-header-dlb-hero@2x.png"
        textOverlay={{
          heading: 'Digital Lesson Bundles',
          pageTitle: 'Educators',
        }}
      />
      <Section>
        {/* Intro */}
        <Container>
          <Row>
            <Column size={9}>
              <h2>Classroom Resources</h2>
              <p style={{ fontSize: '20px', lineHeight: '28px' }}>
                Find exciting ways to incorporate Engineering Dreams into
                existing curriculum with this suite of interactive classroom
                resources, featuring flexible digital lessons that are aligned
                to the{' '}
                <Link to="/educators/video-topic-series">
                  Problem Solvers for Good Video Topic Series
                </Link>
                , along with a companion educator guide. Empower students to
                solve real-world challenges that they care about by applying the
                engineering design process to challenges within their own
                communities.
              </p>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        {/* Digital lesson bundles section */}
        <Container>
          <div className="digital-lesson-bundles__resource-row-header mb-1">
            Digital Lesson Bundle
          </div>
          <Row>
            <Column size={7}>
              <Resource
                img="img-dlb-presentation@2x.jpg"
                title="We Are Problem-Solvers!"
                audience="6-8 Grade"
                duration="Two class sessions of 45–60 minutes each"
                description="Break down the problem-solving process and get students thinking proactively about creating solutions in their communities with this digital lesson. Use this resource to build interactive classroom connections to the Problem Solvers for Good Video Topic Series as students harness the power of ideation, research, analysis and critical thinking to make an impact in their world."
                withBorder={false}
                actions={[
                  {
                    label: 'Digital Lesson',
                    download: '/pdfs/ASME-DLB-WeAreProblemSolvers-V1.1.pptx',
                    metadata: '.PPT 50.1MB',
                  },
                ]}
              />
            </Column>
            <Column size={4} offset={1}>
              <Resource
                img="img-dlb-teacher-guide@2x.jpg"
                title="Teacher Guide"
                audience="6-8 Grade"
                duration="Two class sessions of 45–60 minutes each"
                description="Find all the resources needed to facilitate hands-on problem-solving in the classroom and beyond. Discover comprehensive educator prep, background information, standards alignment and more."
                withBorder={false}
                actions={[
                  {
                    label: 'Educator Guide',
                    download: '/pdfs/ASME-DLB-WeAreProblemSolvers-V1.0.pdf',
                    metadata: '.PDF 550KB',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section className="pb-0">
        <Subfooter />
      </Section>
    </Layout>
  );
};

export default DigitalLessonBundlesPage;
