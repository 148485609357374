import React from 'react';
import { navigate } from 'gatsby-link';
import { useLocation } from '@reach/router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container } from '../Grid';
import Section from '../Section';
import Image from '../Image';
import './styles.scss';

const Hero = ({ mediaSource, textOverlay, className }) => {
  const { pathname: currentPath } = useLocation();

  const pageTitle = textOverlay && textOverlay.pageTitle;
  const heading = textOverlay && textOverlay.heading;

  const isHome = currentPath === '/';

  if (isHome) {
    return (
      <Section className="pl-0 pr-0 pb-0 pt-0 hero">
        <Image
          filename="img-home-lead-background.png"
          className="hero__home-bg-img"
        />
        <Container fullWidth>
          <div className={classNames('hero__home', className)}>
            <div className="hero__home-logo-container">
              <Image
                filename={'logo-engineering-dreams.png'}
                className="hero__home-program-logo"
              />
              <p className="hero__home-text-overlay">CREATE YOUR BEST WORLD</p>
              <h4 className="hero__home-tagline mt-1.5">
                Empowering K–12 Classrooms with STEM Resources
              </h4>
            </div>
            <Image
              filename="img-home-lead-foreground.png"
              className="hero__home-fg-img"
              onClick={() => navigate('/educators/video-topic-series')}
            />
          </div>
        </Container>
      </Section>
    );
  } else {
    return (
      <Section className="pl-0 pr-0 pb-0 pt-0">
        <div className={classNames('hero', className)}>
          <Image filename={mediaSource} className="hero__bg-img" />
          <div className="hero__text-overlay">
            <Container>
              {pageTitle && (
                <p className="hero__text-overlay-page-title">{pageTitle}</p>
              )}
              <p className="hero__text-overlay-heading">{heading}</p>
            </Container>
          </div>
        </div>
      </Section>
    );
  }
};

Hero.defaultProps = {
  textOverlay: null,
  className: null,
};

Hero.propTypes = {
  /** A path to video or a filename for an image (using the Image Component)
   */
  mediaSource: PropTypes.string,
  /** Text overlay for the image.
   * pageTitle is the path of the page. i.e. Educators
   * heading is what is displayed below the page title. i.e. Video Topic Series
   */
  textOverlay: PropTypes.shape({
    pageTitle: PropTypes.string,
    heading: PropTypes.string.isRequired,
  }),
  /** A custom class name */
  classNames: PropTypes.string,
};

export default Hero;
