import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Button from '../Button';

import './styles.scss';

const Action = ({
  label,
  metadata,
  module,
  moduleName,
  link,
  download,
  noIcon,
  asLink,
  index,
}) => {
  const renderModuleLink = () => {
    return (
      <Button
        to={module}
        target="_blank"
        forceExternal
        data-click-type="Module Launch"
        data-click-name={moduleName}
        className="resource__action-link"
      >
        {!noIcon && (
          <Icon
            marginRight
            name="externallink"
            className="resource__action-icon"
          />
        )}
        {label}
      </Button>
    );
  };

  const renderLink = () => {
    return asLink ? (
      <Link to={link} className="resource__action-link">
        {!noIcon && (
          <Icon marginRight name="module" className="resource__action-icon" />
        )}
        {label}
      </Link>
    ) : (
      <Button to={link} className="resource__action-link">
        {label}
      </Button>
    );
  };

  const renderDownload = () => {
    return (
      <Button
        to={download}
        target="_blank"
        rel="noopener noreferrer"
        className="resource__action-link"
        link={asLink}
        isSecondary={index}
      >
        {!noIcon && (
          <Icon name="download" className="resource__action-icon" marginRight />
        )}
        {label}
      </Button>
    );
  };

  return (
    <div className="resource__action">
      {link && renderLink()}
      {module && renderModuleLink()}
      {download && renderDownload()}
      {metadata && <span className="resource__metadata">{metadata}</span>}
    </div>
  );
};

Action.propTypes = {
  label: PropTypes.node.isRequired,
  metadata: PropTypes.string,
  module: PropTypes.string,
  download: PropTypes.string,
  noIcon: PropTypes.bool,
  asLink: PropTypes.bool,
};

export default Action;
